import { Action, Reducer } from "@reduxjs/toolkit";

export enum EAuthActions {
  UPDATE_TOKEN = "update_token",
  DELETE_TOKEN = "delete_token",
}

export interface IAuthReducer {
  token: string;
}

const INITIAL_STATE = {
  token: "",
};

export const AuthReducer: Reducer<IAuthReducer> = (
  state = INITIAL_STATE,
  action: Action | any
) => {
  switch (action.type) {
    case EAuthActions.UPDATE_TOKEN:
      return { ...state, token: action.payload };
    case EAuthActions.DELETE_TOKEN:
      return INITIAL_STATE;
    default:
      return state;
  }
};
