import React, { Suspense, useEffect, useState } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./scss/style.scss";
import { CContainer, CSpinner } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "./redux/store";
import { EAuthActions } from "./redux/auth";


const loading = (
  <CContainer
    className="h-100"
    style={{
      marginTop: "15%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <CSpinner />
  </CContainer>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = () => {
  const [isLogged, setIsLogged] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state: IStore) => state.auth.token);

  const parseJwt = (token: string) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const isValidToken = (jwtToken?: string) => {
    if (jwtToken === "" || !jwtToken) return false;

    const decodedJwt = parseJwt(jwtToken);
    return !(decodedJwt.exp * 1000 < Date.now());
  };

  useEffect(() => {
    if (token === "" || !isValidToken(token)) {
      setIsLogged(false);
      setRedirect(true);
      dispatch({ type: EAuthActions.DELETE_TOKEN });
    } else {
      setIsLogged(true);
      setRedirect(false);
    }
  }, [token]);

  const PrivateRoute = React.useCallback(() => {
    if (!isLogged)
      return (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/404" element={<Page404 />} />
          <Route path="/500" element={<Page500 />} />
          {redirect ? (
            <Route path="*" element={<Navigate to="/login" />} />
          ) : null}
        </Routes>
      );

    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/404" element={<Page404 />} />
        <Route path="/500" element={<Page500 />} />
        <Route path="*" element={<DefaultLayout />} />
      </Routes>
    );
  }, [isLogged, redirect]);

  return (
    <>
      <ToastContainer />
      <HashRouter>
        <Suspense fallback={loading}>{PrivateRoute()}</Suspense>
      </HashRouter>
    </>
  );
};

export default App;
